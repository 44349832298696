"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { Button } from "@openchatai/hoose/ui";
export const metadata = {
  title: "404",
  description: "Page not found",
  keywords: "404, page not found, not found",
  robots: "noindex, nofollow",
};

export default function NotFoundPage() {
  const router = useRouter();
  return (
    <div className="flex-center h-full w-full">
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="relative mx-auto aspect-square w-56">
          <Image unoptimized src="/404.gif" alt="404" fill />
        </div>
        <h1 className="text-5xl font-bold">404</h1>
        <Button onClick={router.back}>Go back</Button>
      </div>
    </div>
  );
}
